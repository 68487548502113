<template>
  <div class="sobre-nos">
    <video autoplay src="@/assets/video.mp4" muted loop>
      Your browser does not support the video tag.
    </video>

    <div class="overlay" v-if="state.isOverlayVisible">
      <div>
        <img src="@/assets/logo-white.png" alt="" />

        <p>
          Empresa especializada em obras, isolamento de andaimes
          multi-direcionais, isolamento de forros para reparos ou demolições,
          isolamento de área em novas construções e projetos especializados para
          grandes inaugurações.
        </p>
      </div>

      <div>
        <p>
          Obras em shoppings, lojas, hospitais, prédios comerciais e
          residenciais, Equipe especializada em obras com funcionários treinados
          e habilitados em Plataformas elevatórias com certificação NR 35 e NR
          18.
        </p>
      </div>
    </div>

    <div class="sobre">
      <p>
        Empresa especializada em obras, isolamento de andaimes
        multi-direcionais, isolamento de forros para reparos ou demolições,
        isolamento de área em novas construções e projetos especializados para
        grandes inaugurações.
      </p>

      <p>
        Obras em shoppings, lojas, hospitais, prédios comerciais e residenciais,
        Equipe especializada em obras com funcionários treinados e habilitados
        em Plataformas elevatórias com certificação NR 35 e NR 18.
      </p>
    </div>

    <button @click="toggleOverlayVisible">
      <transition mode="out-in">
        <img
          v-if="state.isOverlayVisible"
          src="@/assets/icons/arrow-right.svg"
          alt="Esconder"
        />
        <img v-else src="@/assets/icons/arrow-left.svg" alt="Esconder" />
      </transition>
    </button>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  setup() {
    const state = reactive({ isOverlayVisible: true });

    function toggleOverlayVisible() {
      state.isOverlayVisible = !state.isOverlayVisible;
    }

    return {
      toggleOverlayVisible,
      state,
    };
  },
};
</script>

<style lang="scss" scoped>
.sobre-nos {
  position: relative;
  padding-top: 73px;
  width: 100%;
  background: var(--black);

  video {
    position: relative;
    width: 100%;
    height: calc(100vh - 73px);
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    height: auto;
    background: transparent;
    border: 1px solid var(--white);
    border-right: none;
    border-radius: 0.875rem 0 0 0.875rem;
    padding: 0;
    overflow-x: hidden;

    cursor: pointer;

    img {
      height: 100%;
      padding-right: 0.5rem;
      transition: all 0.5s;
    }

    &:hover {
      img {
        padding-right: 1.25rem;
      }

      span {
        display: inline;
      }
    }
  }
}
.overlay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  margin-top: 73px;
  height: calc(100% - 73px);
  width: 100%;
  top: 0;
  background: #000000bf;

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 5rem 0 5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 1.25rem;

    img {
      max-width: 470px;
    }

    p {
      color: var(--white);
      max-width: 556px;
      line-height: 240%;
    }
  }
}

.sobre {
  display: none;
  flex-direction: column;
  margin: 1rem 1rem 0 1rem;

  p {
    max-width: 800px;
    margin: auto;
    font-size: 1rem;
    color: var(--white);
    line-height: 2rem;
    text-align: justify;
    font-weight: 500;

    &:nth-child(2) {
      margin: 1rem auto;
    }
  }
}

.v-enter-from,
.v-leave-to {
  transform: translateX(100%);
}

@media (max-width: 1212px) {
  .overlay {
    display: none;
  }

  .sobre-nos button {
    display: none;
  }

  .sobre {
    display: flex;
  }
}

@media (max-width: 1115px) {
  .sobre-nos {
    video {
      height: 100%;
    }
  }
}

@media (max-width: 640px) {
  .sobre-nos {
    padding-top: 0rem;
  }
}
</style>