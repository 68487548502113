<template>
  <div class="card">
    <img src="@/assets/icons/phone-yellow.svg" alt="Celular" />
    <div class="container">
      <h3>Contato</h3>
      <div>
        <span>+55 11 98896-2733</span>
        <span>intensioneeventos@gmail.com</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.card {
  display: flex;
  border-radius: 16px;
  background: var(--white);
  box-shadow: 0px 2px 7px #00000050;
  padding: 2.5rem;
  height: 100%;
  max-width: 425px;
  cursor: pointer;

  transition: transform 0.5s;

  img {
    height: 4rem;
  }

  .container {
    margin-left: 1.5rem;

    h3 {
      font-size: 1.5rem;
      font-weight: 500;
      margin-bottom: 1.5rem;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    span {
      font-size: 1rem;
      font-weight: 500;
      color: #838383;
    }
  }

  &:hover {
    transform: translateY(-10%);
  }
}

@media (max-width: 350px) {
  .card {
    padding: 1.5rem;
  }
}
</style>