<template>
  <hr />
  <div class="contact" :id="id">
    <div class="email">
      <h2>Tem alguma dúvida?</h2>
      <span>
        Estamos aqui para ajudar. Envie uma mensagem para nós e entraremos em
        contato.
      </span>
      <Form />
    </div>
    <a target="_blank" href="https://api.whatsapp.com/send?phone=5511988962733">
      <Card class="card" />
    </a>
  </div>
</template>

<script>
import Form from "@/components/Actions/Form.vue";
import Card from "../Card.vue";
export default {
  components: { Form, Card },
  props: {
    id: String,
  },
};
</script>

<style lang="scss" scoped>
hr {
  height: 2px;
  background: var(--gray);
  width: 100%;
}
.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 1954px;
  margin: 1rem 2rem 2rem;

  h2 {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2rem;
    font-weight: 700;
  }

  span {
    color: #000;
    font-weight: 500;
    display: inline-block;
    margin-bottom: 2.5rem;
  }

  a {
    margin-top: 2rem;
    height: 100%;
    color: inherit;
    text-decoration: none;
  }

  .email {
    width: 100%;
    max-width: 950px;
    margin-bottom: 4rem;
  }
}

@media (max-width: 1345px) {
  .contact {
    flex-direction: column;

    a {
      margin-top: 0;
    }

    .card {
      margin-top: 1rem;
    }
  }
}

@media (max-width: 900px) {
  .contact {
    .email {
      margin-bottom: 2rem;
    }

    a {
      display: flex;
      justify-content: center;
    }
  }
}
@media (max-width: 450px) {
  .contact {
    .email {
      margin-bottom: 1rem;
    }
  }
}
</style>