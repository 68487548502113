<template>
  <div class="dropdown">
    <div class="activator">
      <span>{{ title }}</span>
      <img src="@/assets/icons/arrow-down.svg" alt="Mais" />
    </div>
    <transition name="fade">
      <div class="sub-menu">
        <div class="dropdown-item" v-for="item in items" :key="item.link">
          <a :href="item.link">{{ item.title }}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    title: String,
    items: Array,
  },
  setup() {
    const isOpen = ref(false);

    function toggleDropdownVisible() {
      isOpen.value = !isOpen.value;
    }

    return { isOpen, toggleDropdownVisible };
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  &:hover {
    .sub-menu {
      display: block;
      opacity: 1;
    }
  }
  .activator {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.5s;

    &:hover {
      transform: scale(110%);

      img {
        transform: rotate(180deg);
      }
    }
    span {
      cursor: pointer;
    }
    img {
      transition: transform 0.3s;
      cursor: pointer;
      width: 25px;
      height: 25px;
    }
  }
  .sub-menu {
    display: none;
    opacity: 0;
    position: absolute;
    top: calc(1.5rem);
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s ease-out;
    width: max-content;

    a {
      width: 100%;
      color: var(--black);
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
      transition: transform 0.5s;
      padding: 1rem;
    }
  }

  .dropdown-item {
    display: flex;
    justify-items: center;
    align-items: center;

    height: 3rem;
    width: 100%;
    background: var(--white);

    &:hover {
      filter: brightness(0.9);
      a {
        transform: scale(110%);
        filter: brightness(0);
      }
    }

    &:last-child {
      border-radius: 0 0 1rem 1rem;
    }
  }
}
</style>