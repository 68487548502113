<template>
  <header>
    <a href="#sobre-nos">
      <img
        src="@/assets/logo-horizontal.png"
        alt="Intensione | Produções & Eventos"
      />
    </a>

    <nav>
      <ul>
        <li><a href="#sobre-nos">Sobre nós</a></li>
        <Dropdown title="Serviços" :items="services" style="margin: 0 3rem" />
        <li>
          <Button type="a" href="#contato" class="button">Contato</Button>
        </li>
      </ul>
    </nav>
  </header>
  <transition name="slide" mode="out-in">
    <div @click="toggleMenuVisible" v-if="!isMobileMenuOpen" class="mobile">
      <img src="@/assets/icons/menu.svg" alt="" />
    </div>

    <div class="menu-mobile" v-else>
      <img
        @click="toggleMenuVisible"
        class="close-button"
        src="@/assets/icons/close.svg"
        alt="Close"
      />
      <img src="@/assets/favicon.png" alt="Logo" />
      <nav>
        <ul>
          <li
            @click="toggleMenuVisible"
            v-for="service in mobileServices"
            :key="service.link"
          >
            <img
              class="icon"
              :src="require(`@/assets/icons/${service.icon}.svg`)"
              :alt="service.title"
            />
            <a :href="service.link">{{ service.title }}</a>
          </li>
        </ul>
      </nav>
    </div>
  </transition>
</template>

<script>
import Button from "@/components/Actions/Button.vue";
import { ref } from "vue";
import Dropdown from "./Dropdown.vue";

export default {
  components: {
    Button,
    Dropdown,
  },
  setup() {
    const services = [
      { title: "Obras", link: "#obras" },
      { title: "Eventos", link: "#eventos" },
      { title: "Decoração em Lycra", link: "#lycra" },
    ];

    const mobileServices = [
      { title: "Sobre nós", link: "#sobre-nos", icon: "user" },
      { title: "Obras", link: "#obras", icon: "engineer" },
      { title: "Eventos", link: "#eventos", icon: "event" },
      { title: "Lycra", link: "#lycra", icon: "spotlight" },
      { title: "Contato", link: "#contato", icon: "phone" },
    ];
    const isMobileMenuOpen = ref(false);

    function toggleMenuVisible() {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
    }

    return { services, isMobileMenuOpen, toggleMenuVisible, mobileServices };
  },
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  z-index: 1;

  padding: 0 5rem 0 5rem;
  width: 100%;
  background: var(--white);

  border-bottom: 0.5px solid var(--gray);

  img {
    cursor: pointer;
  }

  ul {
    display: flex;
    align-items: center;

    height: 100%;
    list-style: none;
    padding: 0;

    li {
      &:hover {
        transform: scale(110%);
      }
      transition: transform 0.5s;

      a {
        color: var(--black);
        font-style: normal;
        font-weight: 400;
        text-decoration: none;
      }

      .button {
        font-weight: 600;
        color: var(--black);
      }
    }
  }
}

.mobile {
  display: none;
  position: fixed;
  z-index: 1;
  top: 1rem;
  left: 1rem;

  height: 2.5rem;
  width: 2.5rem;
  padding: 0.3rem;

  background: var(--white);
  border: solid 1px var(--gray);
  border-radius: 0.75rem;
  cursor: pointer;

  img {
    height: 100%;
  }

  &:hover {
    filter: brightness(0.8);
  }
}

.menu-mobile {
  display: none;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 40%;
  background: var(--white);
  border: 1px solid var(--gray);

  .close-button {
    top: 0.5rem;
    right: 0.5rem;
    position: absolute;
    cursor: pointer;
    height: 1.5rem;
    width: max-content;
    margin: 0;
  }

  img {
    width: 67px;
    margin: 1rem 0 2.5rem;
  }

  nav {
    height: 100%;
    width: 100%;
    background: var(--yellow);

    ul {
      margin-top: 10rem;
      list-style: none;
      padding: 0;
      height: 100%;

      display: flex;
      justify-content: flex-start;
      flex-direction: column;

      gap: 2.9rem;

      li {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        transition: transform 0.25s linear;
        .icon {
          height: 30px;
          margin: 0;
        }

        a {
          text-decoration: none;
          color: var(--black);
          font-weight: 500;
          font-size: 1.25rem;
        }

        &:hover {
          transform: scale(107%);
        }
      }
    }
  }

  // Usar v-transition
}

@media (max-width: 800px) {
  header {
    padding: 0 1rem 0 1rem;
  }
}
@media (max-width: 640px) {
  header {
    display: none;
  }
  .mobile {
    display: block;
  }

  .menu-mobile {
    display: flex;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.25s ease-in-out;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-110%);
}

.slide-enter-to,
.slide-leave-from {
  transform: translateX(0%);
}
</style>