<script>
import Header from "./components/Header.vue";
import SobreNos from "./components/Sections/SobreNos.vue";
import Obras from "./components/Sections/Obras.vue";
import Eventos from "./components/Sections/Eventos.vue";
import Lycra from "./components/Sections/Lycra.vue";
import Contact from "./components/Sections/Contact.vue";
import Footer from "./components/Sections/Footer.vue";
import SplashScreen from "./components/Sections/SplashScreen.vue";

export default {
  components: {
    Header,
    SobreNos,
    Obras,
    Eventos,
    Lycra,
    Contact,
    Footer,
    SplashScreen,
  },
};
</script>

<template>
  <SplashScreen />
  <Header />
  <SobreNos id="sobre-nos" />
  <Obras id="obras" />
  <Eventos id="eventos" />
  <Lycra id="lycra" />
  <Contact id="contato" />
  <Footer />
</template>

<style>
:root {
  --black: #231f20;
  --dark-gray: #454545;
  --gray: #c5c5c5;
  --green: #0a9f46;
  --yellow: #ffcb1f;
  --white: #ffffff;
}

* {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  margin: 0;
  position: relative;
  font-weight: normal;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

:target {
  scroll-margin-top: 4.56rem;
}

@media (max-width: 900px) {
  html {
    font-size: 93.75%;
  }
  :target {
    scroll-margin-top: 0rem;
  }
}

@media (max-width: 770px) {
  html {
    font-size: 87.5%;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 81.25%;
  }
}
</style>
