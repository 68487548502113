<template>
  <div class="input" v-if="input">
    <input
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      v-model="inputValue"
      :maxlength="maxlength"
      @keyup="handleChange"
    />
    <img v-if="icon" :src="require(`@/assets/icons/${icon}.svg`)" :alt="icon" />
  </div>
  <textarea
    v-else
    rows="5"
    :name="name"
    v-model="inputValue"
    @keyup="handleChange"
  />
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    icon: String,
    placeholder: String,
    type: String,
    required: Boolean,
    name: String,
    mask: Boolean,
    maxlength: String,
    input: Boolean,
  },
  emits: ["changeValue"],
  setup(props, { emit }) {
    const inputValue = ref(null);

    function handleChange() {
      if (props.mask) {
        inputValue.value = inputValue.value.replace(/\D/g, "");
        inputValue.value = inputValue.value.replace(/^(\d{2})(\d)/g, "($1) $2");
        inputValue.value = inputValue.value.replace(/(\d)(\d{4})$/, "$1-$2");
      }
      emit("changeValue", inputValue.value, props.name);
    }

    return { inputValue, handleChange };
  },
};
</script>

<style lang="scss" scoped>
input {
  border: 1px solid var(--gray);
  border-radius: 2rem;
  height: 3rem;
  width: 100%;
  font-size: 1.15rem;
  color: #838383;
  padding: 1.5rem 2.6rem 1.5rem 1.5rem;
  outline: none;

  &::placeholder {
    font-size: 1.15rem;
    color: #838383;
  }

  &:focus {
    box-shadow: 0 0 5px var(--black);
  }
}
.input {
  max-width: 400px;
  position: relative;

  img {
    position: absolute;
    right: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

textarea {
  margin-top: 2rem;
  width: 100%;
  border-radius: 1rem;
  font-size: 1.15rem;
  border: 1px solid var(--gray);
  color: #838383;
  padding: 1rem;
  resize: none;

  &::-webkit-scrollbar {
    width: 10px;
    overflow: hidden;
  }

  &::-webkit-scrollbar-track {
    border-radius: 50%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--gray); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
  }
}

@media (max-width: 900px) {
  input {
    height: 2.5rem;
    padding: 0.875rem 2rem 0.875rem 1rem;
    font-size: 1rem;

    &::placeholder {
      font-size: 1rem;
    }
  }

  .input img {
    height: 1.75rem;
  }
}

@media (max-width: 545px) {
  .input {
    max-width: 100%;
  }
}
</style>