<template>
  <div class="splash-screen">
    <img src="@/assets/logo-white.png" alt="Intensione Eventos" />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@keyframes splash {
  0% {
    transform: translateY(-200%);
  }

  75% {
    transform: scale(150%);
  }

  50% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(-50%);
  }
}

@keyframes exit {
  0% {
    bottom: 0;
  }

  100% {
    transform: translateY(0%);
    width: 0px;
  }
}
.splash-screen {
  animation: exit 0.75s forwards;
  animation-delay: 1.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 3;
  overflow: hidden;
  background: var(--black);
  height: 100vh;
  width: 100vw;

  img {
    animation: splash 1s forwards;
  }
}

@media (max-width: 500px) {
  .splash-screen img {
    max-width: 200px;
  }
}
</style>