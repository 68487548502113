<template>
  <div class="carousel">
    <slot :currentSlide="currentSlide" />

    <div class="toggle-page left">
      <img
        @click="prevSlide"
        src="@/assets/icons/arrow-left.svg"
        alt="Anterior"
      />
    </div>
    <div class="toggle-page right">
      <img
        @click="nextSlide"
        src="@/assets/icons/arrow-right.svg"
        alt="Próximo"
      />
    </div>

    <div class="pagination">
      <span
        v-for="(_, index) in getSlideCount"
        :key="index"
        :class="{ active: index + 1 == currentSlide }"
        @click="goToSlide(index)"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
export default {
  props: {
    htmlClass: String,
  },
  setup(props) {
    const currentSlide = ref(1);
    const getSlideCount = ref(null);
    const autoPlayEnabled = ref(true);
    const timeoutDuration = ref(5000);
    const interval = ref(null);

    function nextSlide() {
      autoplay();
      if (currentSlide.value == getSlideCount.value) {
        currentSlide.value = 1;
        return;
      }
      currentSlide.value++;
    }

    function prevSlide() {
      autoplay();
      if (currentSlide.value == 1) {
        currentSlide.value = getSlideCount.value;
        return;
      }
      currentSlide.value--;
    }

    function goToSlide(index) {
      autoplay();
      currentSlide.value = index + 1;
    }

    function autoplay() {
      clearInterval(interval.value);

      if (autoPlayEnabled.value) {
        interval.value = setInterval(() => {
          nextSlide();
        }, timeoutDuration.value);
      }
    }

    onMounted(() => {
      getSlideCount.value = document.querySelectorAll(
        `.${props.htmlClass}`
      ).length;
      autoplay();
    });

    return { currentSlide, nextSlide, prevSlide, getSlideCount, goToSlide };
  },
};
</script>

<style lang="scss" scoped>
.carousel {
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  max-width: 900px;

  width: 100%;
  height: 600px;

  &:hover {
    .toggle-page img {
      opacity: 1;
      transform: translateX(0%);
    }
  }
}
.toggle-page {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
}
.right {
  right: 1rem;

  img {
    transform: translateX(50%);
  }
}

.left {
  left: 1rem;
  img {
    transform: translateX(-50%);
  }
}

img {
  transition: all 0.2s ease-in;
  opacity: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #8a8a8a80;
}

.pagination {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  span {
    cursor: pointer;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: var(--white);
  }

  .active {
    background: var(--yellow);
  }
}

@media (max-width: 900px) {
  .carousel {
    border-radius: 0rem;
  }
}
</style>