<template>
  <div class="obras">
    <h2>Obras <img src="@/assets/icons/engineer.svg" alt="Obras" /></h2>
    <p>
      Equipe especializada em obras de shoppings, hospitais e prédios
      comerciais. Prestação de serviço para construtoras, onde nosso objetivo é
      fazer com que o cliente não possua condições de visualizar o perímetro da
      obra por estética ou por segurança.
    </p>

    <Carousel
      class="carousel"
      :class="{ fullscreen: isFullscreen }"
      v-slot="{ currentSlide }"
      htmlClass="slide-obras"
    >
      <img
        v-if="!isFullscreen"
        @click="toggleFullscreen"
        class="fullscreen-button"
        src="@/assets/icons/fullscreen.svg"
        alt="Fullscreen"
      />
      <img
        v-else
        @click="toggleFullscreen"
        @keyup="toggleFullscreen"
        class="fullscreen-button"
        src="@/assets/icons/fullscreen-exit.svg"
        alt="Fullscreen exit"
      />

      <Slide
        v-for="slide in carouselSlides"
        :key="slide"
        htmlClass="slide-obras"
        @click="toggleFullscreen"
      >
        <div v-show="currentSlide == slide" class="slide-info">
          <img :src="getImagePath(slide)" :alt="`Imagem obras ${slide}`" />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel/Carousel.vue";
import Slide from "@/components/Carousel/Slide.vue";
import { ref } from "vue";

export default {
  name: "Obras",
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const carouselSlides = 18;

    const isFullscreen = ref(false);

    function getImagePath(slide) {
      let imgPath;
      try {
        imgPath = require(`@/img/obras/${slide}.jpg`);
      } catch (err) {
        imgPath = imgPath ? imgPath : require(`@/img/obras/${slide}.png`);
      }

      return imgPath;
    }

    function toggleFullscreen() {
      isFullscreen.value = !isFullscreen.value;
    }

    return { carouselSlides, getImagePath, toggleFullscreen, isFullscreen };
  },
};
</script>

<style lang="scss" scoped>
.obras {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    max-width: 900px;

    line-height: 150%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
}
.carousel {
  margin-bottom: 2rem;
  position: relative;
  background: rgb(0, 0, 0);
  cursor: pointer;

  .fullscreen-button {
    position: absolute;
    height: 30px;
    z-index: 1;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
  }
}
.fullscreen {
  border-radius: 0;
  position: fixed;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  max-width: 100%;

  .slide-info {
    height: 100vh;
  }
}
.slide-info {
  position: absolute;
  width: 100%;
  height: 600px;

  img {
    border-radius: 0.75rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (max-width: 900px) {
  .obras p {
    margin: 0 1rem 1.5rem;
  }
  .carousel {
    margin-bottom: 0;
  }
  .slide-info img {
    border-radius: 0;
  }
}
</style>
