<template>
  <div class="lycra">
    <h2>
      Decoração em lycra
      <img src="@/assets/icons/spotlight.svg" alt="Holofote" />
    </h2>
    <p>
      Fechamento e decoração de espaços criando um cenário diferente e
      modernizando seu evento, com ajuda das luzes cênicas transformamos seu
      espaço em um evento único!
    </p>

    <Carousel
      class="carousel"
      :class="{ fullscreen: isFullscreen }"
      v-slot="{ currentSlide }"
      htmlClass="slide-lycra"
    >
      <img
        v-if="!isFullscreen"
        @click="toggleFullscreen"
        class="fullscreen-button"
        src="@/assets/icons/fullscreen.svg"
        alt="Fullscreen"
      />
      <img
        v-else
        @click="toggleFullscreen"
        class="fullscreen-button"
        src="@/assets/icons/fullscreen-exit.svg"
        alt="Fullscreen exit"
      />
      <Slide
        v-for="slide in carouselSlides"
        :key="slide"
        htmlClass="slide-lycra"
      >
        <div
          v-show="currentSlide == slide"
          class="slide-info"
          @click="toggleFullscreen"
        >
          <img :src="getImagePath(slide)" :alt="`Imagem lycra ${slide}`" />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import Carousel from "@/components/Carousel/Carousel.vue";
import Slide from "@/components/Carousel/Slide.vue";
import { ref } from "vue";

export default {
  name: "Lycra",
  components: {
    Carousel,
    Slide,
  },
  setup() {
    const carouselSlides = 9;

    const isFullscreen = ref(false);

    function getImagePath(slide) {
      let imgPath;
      try {
        imgPath = require(`@/img/lycra/${slide}.jpg`);
      } catch (err) {
        imgPath = imgPath ? imgPath : require(`@/img/lycra/${slide}.png`);
      }

      return imgPath;
    }

    function toggleFullscreen() {
      isFullscreen.value = !isFullscreen.value;
    }

    return { carouselSlides, getImagePath, toggleFullscreen, isFullscreen };
  },
};
</script>

<style lang="scss" scoped>
.lycra {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  p {
    max-width: 900px;

    line-height: 150%;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1.5rem;
  }
}
.carousel {
  margin-bottom: 2rem;
  background: #000000;
  cursor: pointer;

  .fullscreen-button {
    position: absolute;
    height: 30px;
    z-index: 1;
    top: 0.5rem;
    right: 0.5rem;
    cursor: pointer;
  }
}
.fullscreen {
  border-radius: 0;
  position: fixed;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 100%;
  max-width: 100%;

  .slide-info {
    height: 100vh;
  }
}
.slide-info {
  position: absolute;
  width: 100%;
  height: 600px;

  img {
    border-radius: 0.75rem;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
@media (max-width: 900px) {
  .lycra {
    p {
      margin: 0 1rem 1.5rem;
    }
  }
  .carousel {
    margin-bottom: 0;
  }
  .slide-info img {
    border-radius: 0;
  }
}
</style>
