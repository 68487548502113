<template>
  <transition name="fade" mode="out-in">
    <form v-if="response.type != 'success'">
      <div class="inputs">
        <Input
          input
          icon="user-gray"
          placeholder="Nome *"
          type="text"
          name="name"
          required
          @change-value="handleChange"
        />
        <Input
          input
          icon="email"
          placeholder="Email *"
          type="email"
          name="email"
          required
          @change-value="handleChange"
        />
        <Input
          input
          icon="phone-gray"
          placeholder="Telefone *"
          type="tel"
          name="phone"
          :required="true"
          :mask="true"
          maxlength="15"
          @change-value="handleChange"
        />
      </div>
      <Input name="message" maxlength="255" @change-value="handleChange" />

      <Button
        class="button"
        typeButton="button"
        type="submit"
        @click.prevent="handleSubmit"
      >
        Enviar
      </Button>
    </form>
    <div v-else class="message-sended">
      <img src="@/assets/icons/check.svg" alt="Check" />
      <span>
        Sua mensagem foi enviada com sucesso, em breve entraremos em contato com
        você!
      </span>
    </div>
  </transition>
</template>

<script>
import Input from "./Input.vue";
import Button from "./Button.vue";
import { reactive } from "vue";
export default {
  components: { Input, Button },
  setup() {
    let contact = reactive({
      name: "",
      email: "",
      phone: "",
      message: "Olá, tenho interesse em organizar um evento!",
      subject: "Mensagem de contato",
      // accessKey: "f5f880dd-8727-4815-84f9-7ef1c1a89c58",
      accessKey: "c9b4d7db-0363-4266-b07d-f8d219130d24",
    });

    const response = reactive({
      type: "",
      message: "",
    });

    function handleChange(value, name) {
      contact = { ...contact, [name]: value };
    }

    async function handleSubmit() {
      try {
        if (
          contact.email != "" &&
          contact.name != "" &&
          contact.phone != "" &&
          contact.email.includes("@")
        ) {
          const res = await fetch("https://api.staticforms.xyz/submit", {
            method: "POST",
            body: JSON.stringify(contact),
            headers: { "Content-Type": "application/json" },
          });

          const json = await res.json();

          if (json.success) {
            response.type = "success";
            response.message = "Sua mensagem foi enviada com sucesso!";
          } else {
            response.type = "error";
            response.message = json.message;
          }
        }
      } catch (e) {
        console.error("An error occurred", e);
        response.type = "error";
        response.message = "Ocorreu um erro, tente novamente mais tarde!";
      }
    }

    return { handleChange, handleSubmit, response };
  },
};
</script>

<style lang="scss" scoped>
form {
  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
    column-gap: 1rem;
    grid-template-rows: 1fr 1fr;
  }

  .button {
    margin-top: 1rem;
    font-size: 1.15rem;
    font-weight: 700;

    transition: transform 0.5s;

    &:hover {
      transform: translateY(-0.35rem);
    }
  }
}

.message-sended {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;

  span {
    margin-left: 1rem;
    color: #000;
    font-weight: 500;
    display: inline-block;
  }
}

@media (max-width: 545px) {
  form {
    .inputs {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 1.5s ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
