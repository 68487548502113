<template>
  <button v-if="typeButton == 'button'" :type="type">
    <slot />
  </button>
  <a :href="href" v-else>
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    type: String,
    typeButton: String,
    href: String,
  },
};
</script>

<style lang="scss" scoped>
button,
a {
  background: var(--yellow);
  border: none;
  border-radius: 2rem;
  padding: 0.5rem 1rem 0.5rem 1rem;

  font-weight: 600;
  color: var(--black);

  cursor: pointer;

  &:hover {
    filter: brightness(0.9);
  }
}
</style>