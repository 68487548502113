<template>
  <footer>
    <img src="@/assets/logo-cards.png" alt="Intensione" />
    <div class="infos">
      <img src="@/assets/logo-horizontal-white.png" alt="Produções e Eventos" />
      <span>
        Realizando qualquer tipo de evento sempre com maestria e emoção.
      </span>
      <div class="icons">
        <a href="https://pt-br.facebook.com/intensioneeventos/" target="_blank">
          <img src="@/assets/icons/facebook.svg" alt="Facebook" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  setup() {
    const services = [
      "Estrutura de palco som e luz",
      "Fechamentos e isolamentos para obras",
      "Forração de tendas",
      "Forração em carpete",
      "Luz cênica",
      "Tecidos para cineografia",
    ];

    return { services };
  },
};
</script>

<style lang="scss" scoped>
footer {
  max-height: 300px;
  display: flex;
  width: 100%;
  background: var(--black);

  .infos {
    margin-top: 1.5rem;
    margin-left: 16rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: var(--white);

    img {
      display: block;
      max-width: 265px;
      max-height: 68px;
    }

    span {
      font-weight: 400;
      font-size: 0.875rem;
      max-width: 400px;
      width: 100%;
    }
  }

  .icons {
    margin-top: 0.5rem;
    display: flex;
    gap: 1rem;

    a {
      transition: transform 0.5s;
      &:hover {
        transform: translateY(-0.5rem);
      }
    }

    img {
      background: var(--yellow);
      padding: 6px;
      border-radius: 1rem;
      width: 45px;
    }
  }
}

@media (max-width: 1345px) {
  footer {
    justify-content: space-between;
  }
}

@media (max-width: 900px) {
  footer {
    display: flex;
    justify-content: center;
    .infos {
      margin-left: 0;
    }
    img {
      display: none;
    }

    padding-bottom: 1rem;
  }
}
@media (max-width: 625px) {
  footer {
    .infos {
      max-width: 370px;
      margin-right: 1rem;
    }
    .icons {
      img {
        background: var(--yellow);
        padding: 4px;
        border-radius: 0.7rem;
        width: 30px;
      }
    }
  }
}
@media (max-width: 480px) {
  footer {
    .infos {
      max-width: 300px;
      margin-right: 1rem;
    }
  }
}
</style>